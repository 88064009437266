import React, { useEffect, useRef } from 'react';
import { MindARThree } from 'mind-ar/dist/mindar-image-three.prod.js';
import * as THREE from 'three';

const MindARThreeViewer = ({ imageTarget, assets }) => {
  const containerRef = useRef(null);
  const mindarThreeRef = useRef(null);
  const planeRef = useRef({});
  let renderer;

  useEffect(() => {
    console.log('Mounting MindARThreeViewer with target:', imageTarget);

    const cleanUp = () => {
      console.log('Cleaning up MindARThree instance');
      if (mindarThreeRef.current) {
        mindarThreeRef.current.stop();
        if (renderer) renderer.setAnimationLoop(null);
        Object.values(planeRef.current).forEach((plane) => {
          if (plane) {
            plane.geometry.dispose();
            plane.material.dispose();
          }
        });
        planeRef.current = {};
      }
    };

    if (mindarThreeRef.current) cleanUp();

    mindarThreeRef.current = new MindARThree({
      container: containerRef.current,
      imageTargetSrc: imageTarget,
    });

    const { renderer: mindarRenderer, scene, camera } = mindarThreeRef.current;
    renderer = mindarRenderer;

    const smoothingFactor = 0.1;
    const smoothedPositions = {};
    const smoothedRotations = {};

    assets.forEach((asset, index) => {
      const anchor = mindarThreeRef.current.addAnchor(asset.targetIndex);

      const video = document.createElement('video');
      video.src = asset.video;
      video.loop = true;
      video.crossOrigin = 'anonymous';

      const texture = new THREE.VideoTexture(video);

      const img = new Image();
      img.src = asset.imageSrc;
      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        const videoWidth = 1; // Base width for scaling
        const videoHeight = videoWidth / aspectRatio;

        let material;

        if (asset.useCircleMask) {
          const maskTexture = new THREE.TextureLoader().load('/assets/mask.png');
          material = new THREE.MeshBasicMaterial({
            map: texture,
            alphaMap: maskTexture,
            transparent: true,
          });
        } else {
          material = new THREE.MeshBasicMaterial({ map: texture });
        }

        const geometry = new THREE.PlaneGeometry(videoWidth, videoHeight);
        const plane = new THREE.Mesh(geometry, material);

        planeRef.current[index] = plane;
        anchor.group.add(plane);

        smoothedPositions[index] = new THREE.Vector3();
        smoothedRotations[index] = new THREE.Euler();

        anchor.onTargetFound = () => {
          console.log('Target found for:', asset.personName);
          video.play();
        };

        anchor.onTargetLost = () => {
          console.log('Target lost for:', asset.personName);
          video.pause();
        };
      };

      anchor.onUpdate = (anchorData) => {
        console.log('Anchor position:', anchorData.position);
        console.log('Anchor rotation:', anchorData.rotation);
      };
    });

    mindarThreeRef.current
      .start()
      .then(() => {
        console.log('MindARThree instance started successfully with target:', imageTarget);

        renderer.setAnimationLoop(() => {
          assets.forEach((_, index) => {
            const plane = planeRef.current[index];
            if (plane) {
              const currentPosition = plane.position;
              const currentRotation = plane.rotation;

              // Smooth position
              smoothedPositions[index].lerp(currentPosition, smoothingFactor);
              plane.position.copy(smoothedPositions[index]);

              // Smooth rotation
              smoothedRotations[index].x +=
                (currentRotation.x - smoothedRotations[index].x) * smoothingFactor;
              smoothedRotations[index].y +=
                (currentRotation.y - smoothedRotations[index].y) * smoothingFactor;
              smoothedRotations[index].z +=
                (currentRotation.z - smoothedRotations[index].z) * smoothingFactor;
              plane.rotation.copy(smoothedRotations[index]);
            }
          });

          renderer.render(scene, camera);
        });
      })
      .catch((error) => console.error('Error starting MindARThree instance:', error));

    return cleanUp;
  }, [imageTarget, assets]);

  return <div style={{ width: '100%', height: '100%' }} ref={containerRef}></div>;
};

export default MindARThreeViewer;
